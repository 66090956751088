<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="store-list-container">

    <b-card no-body>
      <b-card-header>
        <b-card-title>Danh sách hiệu thuốc</b-card-title>
        <crud-buttons
          name="hiệu thuốc"
          modal="modal-drug-store"
          :selected-rows="selectedRows"
          :show-import="false"
          :show-export="true"
          link-template-file="https://resident.sgp1.digitaloceanspaces.com/dev-resident/import/Ba%CC%89ng%20nha%CC%A3%CC%82p%20lie%CC%A3%CC%82u%20ca%CC%86n%20ho%CC%A3%CC%82.xlsx"
          @on-delete="onDelete"
          @on-add="resetItem"
        />
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md="3">
            <select-province
              v-model="province"
              :label="null"
            />
          </b-col>
          <b-col md="3">
            <select-district
              v-model="district"
              :province="province"
              :label="null"
            />
          </b-col>
          <b-col md="3">
            <select-ward
              v-model="ward"
              :province="district"
              :label="null"
            />
          </b-col>
          <b-col md="3">
            <b-form-input
              v-model="searchTerm"
              placeholder="Tìm kiếm ..."
              debounce="1000"
            />
          </b-col>
        </b-row>
        <b-row class="mt-2">
          <b-col cols="12">
            <vue-good-table
              mode="remote"
              :total-rows="totalRecords"
              :is-loading.sync="isLoading"
              :columns="columns"
              :rows="rows"
              :search-options="{
                enabled: false,
              }"

              :pagination-options="{
                enabled: true,
                perPage: serverParams.length,
              }"
              @on-selected-rows-change="selectionChanged"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
            >
              <template slot="loadingContent">
                <b-spinner
                  label="Loading"
                  type="grow"
                />
              </template>
              <template
                slot="table-row"
                slot-scope="props"
              >
                <!-- Column: Code -->
                <span v-if="props.column.field === 'code'">
                  <b-link
                    v-b-modal.modal-apartment-detail
                    class="font-weight-bold"
                    @click="onEditItem(props.row)"
                  >
                    {{ props.row.code }}
                  </b-link>
                </span>
                <span v-else-if="props.column.field === 'item'">
                  <b-media vertical-align="center">
                    <template #aside>
                      <b-avatar
                        rounded
                        :src="`https://api.vtelehealth.vn:8443/media/view?token=${encodeURIComponent(props.row.profile_picture)}-300.jpeg`"
                        size="48"
                      />
                    </template>
                    <b-link
                      class="font-weight-bold d-block text-nowrap"
                    >
                      {{ props.row.name }}
                    </b-link>
                    <small class="text-muted">{{ props.row.detail_address }}</small>
                  </b-media>
                </span>

                <span v-else-if="props.column.field === 'represent'">
                  <span>{{ props.row.owner_name }}</span>
                  <small
                    v-if="props.row.phone"
                    class="text-muted"
                  ><br>{{ props.row.phone }}</small>
                </span>

                <span v-else-if="props.column.field === 'status'">
                  <b-badge
                    pill
                    :variant="resolveStatusVariant(props.row.status).variant"
                    class="font-small-1"
                  >
                    {{ resolveStatusVariant(props.row.status).title }}
                  </b-badge>
                </span>
                <span v-else-if="props.column.field === 'time'">
                  {{ resolveWorkingTime(props.row) }}
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <table-actions
                    name="hiệu thuốc"
                    modal="modal-drug-store"
                    @on-edit="onEditItem(props.row)"
                    @on-delete="onDelete(props.row)"
                  />
                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap"> Hiển thị tối đa </span>
                    <b-form-select
                      v-model="serverParams.length"
                      :options="['10', '50', '100', '500']"
                      class="mx-1"
                      @input="
                        (value) =>
                          props.perPageChanged({ currentPerPage: value })
                      "
                    />
                    <span class="text-nowrap">
                      trên tổng số {{ props.total }} kết quả
                    </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="serverParams.length"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                        (value) => props.pageChanged({ currentPage: value })
                      "
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
              <div slot="emptystate">
                <div class="text-center text-muted">
                  Không có bản ghi nào!
                </div>
              </div>
            </vue-good-table>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <drug-store-modal
      :item="item"
      @refetch-data="fetchData"
    />
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BLink,
  BPagination,
  BFormSelect,
  BSpinner,
  BAvatar,
  BMedia,
  BBadge,
} from "bootstrap-vue";

import { VueGoodTable } from "vue-good-table";
import TableActions from "@/views/components/TableActions.vue";
import CrudButtons from "@/views/components/CrudButtons.vue";
// eslint-disable-next-line import/no-cycle
import SelectProvince from '@/views/components/SelectProvince.vue';
import SelectDistrict from '@/views/components/SelectDistrict.vue';
import SelectWard from '@/views/components/SelectWard.vue';
import DrugStoreModal from '../modal/DrugStoreModal.vue';
import useDrugStoreList from "./useDrugStoreList";

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BLink,
    BPagination,
    BAvatar,
    BFormSelect,
    BSpinner,
    BMedia,
    TableActions,
    CrudButtons,
    VueGoodTable,
    BBadge,
    DrugStoreModal,
    SelectProvince,
    SelectDistrict,
    SelectWard,
  },

  setup() {
    const {
      analytics,
      item,
      columns,
      rows,
      province,
      district,
      ward,
      status,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,

      fetchData,
      deletePharmacy,
      onEditItem,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,

      resolveStatusVariant,
      resolveWorkingTime,
    } = useDrugStoreList();

    return {
      analytics,
      item,
      columns,
      rows,
      province,
      district,
      ward,
      status,
      selectedRows,
      searchTerm,
      isLoading,
      totalRecords,
      serverParams,

      fetchData,
      deletePharmacy,
      onEditItem,
      resetItem,
      updateParams,
      onPageChange,
      onPerPageChange,
      onSortChange,
      onColumnFilter,
      selectionChanged,

      resolveStatusVariant,
      resolveWorkingTime,
    };
  },

  created() {
    this.fetchData();
  },

  methods: {
    onDelete(item) {
      this.deletePharmacy(item._id);
    },
    onEdit(apartment) {

    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
.vgt-wrap {
  .vgt-table {
    thead {
      th {
        font-size: 1rem !important;
        font-weight: 500;
      }
    }
    td {
      font-size: 1rem !important;
      font-weight: 500;
    }
  }
}
.store-list-container {
  .vgt-table {
      thead {
        tr {
          th {
            min-width: 100px !important;

            &:first-child {
              min-width: 50px !important;
            }
            &:nth-child(2) {
               min-width: 80px !important;
            }
            &:nth-child(3) {
               min-width: 80px !important;
            }
            &:nth-child(4) {
               min-width: 180px !important;
            }
            &:nth-child(6) {
               min-width: 120px !important;
               width: 120px !important
            }
          }
        }
      }
    }
}
</style>
